<template>
  <div class='relative pl-4 lg:pl-8 pt-2'>
    <div class='flex flex-row justify-start gap-x-2 items-center text-xs lg:text-base mt-1'>
      <label class='w-24 lg:w-40 text-gray-700 text-right bg-gray-100 p-1'>기업명</label>
      <div class='block'>{{ drilldownPiPortfolio.name }}</div>
    </div>
    <div class='flex flex-row justify-start gap-x-2 items-center text-xs lg:text-base mt-1'>
      <label class='w-24 lg:w-40 text-gray-700 text-right bg-gray-100 p-1'>대표자</label>
      <div class='block'>{{ drilldownPiPortfolio.ceoName }}</div>
    </div>

    <h2 class='mt-8 text-base lg:text-xl font-medium text-gray-800 mb-1'>투자정보</h2>
    <div class='flex flex-row justify-start gap-x-1 items-center text-xs lg:text-base mt-1'>
      <label class='w-24 lg:w-40 text-gray-700 text-right bg-gray-100 p-1'>총 투자원금</label>
      <div class='text-gray-800 text-right w-24 pr-1'>{{ toNumber(investmentUkValue(drilldownPiPortfolio.investmentValue)) }}</div>
      <div class='text-gray-600'>억원</div>
    </div>
    <div class='flex flex-row justify-start gap-x-1 items-center text-xs lg:text-base mt-1'>
      <label class='w-24 lg:w-40 text-gray-700 text-right bg-gray-100 p-1'>평균투자단가</label>
      <div class='text-gray-800 text-right w-24 pr-1'>{{ toNumber(drilldownPiPortfolio.investmentPrice) }}</div>
      <div class='text-gray-600'>원</div>
    </div>
    <div class='flex flex-row justify-start gap-x-1 items-center text-xs lg:text-base mt-1'>
      <label class='w-24 lg:w-40 text-gray-700 text-right bg-gray-100 p-1'>총 보유수량</label>
      <div class='text-gray-800 text-right w-24 pr-1'>{{ toNumber(drilldownPiPortfolio.holdingQuantity) }}</div>
      <div class='text-gray-600'>주</div>
    </div>
    <div class='flex flex-row justify-start gap-x-1 items-center text-xs lg:text-base mt-1'>
      <label class='w-24 lg:w-40 text-gray-700 text-right bg-gray-100 p-1'>발행주식총수</label>
      <div class='text-gray-800 text-right w-24 pr-1'>{{ toNumber(drilldownPiPortfolio.totalIssueQuantity) }}</div>
      <div class='text-gray-600'>주</div>
    </div>
    <div class='w-full overflow-x-auto'>
      <pi-portfolio-summaries class='lg:pr-8' />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import numbro from 'numbro'
import PiPortfolioSummaries from '@/views/portfolio/PiPortfolioSummaries.vue'

export default {
  name: 'PiPortfolioInformation',
  components: {
    PiPortfolioSummaries,
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentId',
    ]),
    ...mapState('piPortfolios', [
      'drilldownPiPortfolio',
      'tmsPiFills',
    ]),
    filteredTmsPiFills () {
      return this.tmsPiFills.filter(fill => fill.shareCode === this.drilldownPiPortfolio.shareCode)
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    toNumber (number) {
      if (number) {
        return numbro(parseInt(number)).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    investmentUkValue (investmentValue) {
      return investmentValue ? investmentValue / 100000000 : 0
    },
  },
}
</script>
